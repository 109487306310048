/* Create a new file called 'projects.css' and import it in your Projects component */

/* Core animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animation utility classes */
.animate-fade-in {
  animation: fadeIn 1s ease-out forwards;
}

.animate-slide-up {
  animation: slideUp 1s ease-out forwards;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

/* Gradient background animation */
@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradientFlow 15s ease infinite;
}

/* Pulse animation */
@keyframes pulse {
  0%, 100% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.3;
  }
}

.animate-pulse {
  animation: pulse 4s ease-in-out infinite;
}

/* Custom styles for projects section */
.project-hero {
  position: relative;
  height: 40vh;
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.project-card-wrapper {
  position: relative;
  transition: transform 0.3s ease;
}

.project-card-wrapper:hover {
  transform: translateY(-5px);
}

.gradient-border {
  position: relative;
}

.gradient-border::before {
  content: '';
  position: absolute;
  inset: -2px;
  background: linear-gradient(to right, #c770f0, #7928ca);
  border-radius: inherit;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gradient-border:hover::before {
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .project-hero {
    height: 30vh;
  }
}